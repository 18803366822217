.loading {
  width: 100vw;
  height: 100vh;
  zoom: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.8);
}

@media only screen and (max-width: 1500px) {
  .loading {
    zoom: 150%;
  }
}
