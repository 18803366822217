@font-face {
  font-family: "Azonix Regular";
  font-weight: normal;
  font-style: normal;
  src: local("Azonix Regular"), url("Azonix.woff") format("woff");
}

.h1 {
  font-family: "Azonix Regular";
}

.headImg {
  height: 120px;
  /* max-width: 125px; */
}

.subheader {
  font-family: "Azonix Regular";
  color: #d8d9de;
  font-size: min(max(1.5em, 3vw), 2em);
  margin-bottom: 40px;
}

.App {
  text-align: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.floatLeft {
  float: left;
}
.floatRight {
  float: right;
}

.particles {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.formPage {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(#0057b8, #81b0b2);
  zoom: 100%;
}
