.navigation {
  zoom: 100%;
}

.isActive {
  color: black;
  /* @apply bb;
  @apply bw2;
  @apply b--near-white; */
}

@media only screen and (max-width: 1000px) {
  .logo {
    zoom: 80%;
  }
  .navigation {
    zoom: 80%;
  }
}
