.modal {
  position: absolute;
  width: 80%;
  height: 80%;
  overflow: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.8);
}
