.betaPage {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  zoom: 100%;
}

.particlesBeta {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-color: black;
}
