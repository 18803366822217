.fullScreen {
  width: 100%;
  height: 100%;
  zoom: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.8);
}
