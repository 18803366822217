.radioNoButton {
  visibility: hidden;
  width: 0px;
}

#languageToggle {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

.radioNoButton:checked + .labelRadio {
  font-weight: 900;
}
